import * as React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../../layouts/default-layout';

import PageHead from '../pageHead';
import SidebarService from "../sidebarService";
import BodyBlockContent from '../bodyBlockContent';

import { extractImageFromData } from '../../lib/helpers';

const ServicesSlugPage = ({ path, pageContext }) => {
  const intl = useIntl()
  const content = pageContext.data
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subtitle ? content.subtitle[intl.locale] : null;

  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path} slugs={content.slug}>
      <PageHead
        headline={title}
        subline={content.subtitle && content.subtitle[intl.locale]}
        backgroundImage={extractImageFromData(content, 'poster')}
        textWhite={content.lightHeader}
      />

      <div className="relative">
        <div id="jumpmark" className="w-full pb-12 pointer-events-none tablet:absolute tro-default-padding-x large:pb-0">
          <div className="tablet:w-1/4">
            <SidebarService
              className="-mx-4 pointer-events-auto tablet:mx-0 tablet:sticky tablet:top-12"
              entries={pageContext.allServices}
              isSubpage={true}
            />
          </div>
        </div>

        <BodyBlockContent data={content._rawBody[intl.locale]} />
      </div>
    </Layout>
  );
};

export default ServicesSlugPage;
